









































































































import './styles/InviteMemberForm.scss';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IPackPD } from '@store/modules/project-documents/Interfaces';
import { IProjectTeamState, IMemberRight } from '@store/modules/project-team/interfaces/Interfaces';
import { ProjectTeamActions } from '@store/modules/project-team/Types';
import { IInviteMemberPostData } from '@store/modules/project-team/interfaces/ApiRequests';
import { formatDate } from '@utility/formatDate';
import { makeFocusInput } from '@utility/makeFocusInput.js';
import { IMemberRole, IProject } from "@store/modules/project/Interfaces";
import { IJuristicEntity } from "@store/modules/about-project/Interfaces";

const NSProject = namespace('storeProject');
const NSProjectTeam = namespace('storeProjectTeam');
const NSDocuments = namespace('storeProjectDocuments');
const NSAboutProject = namespace('storeAboutProject');

class InitialInviteMemberFormData {
    workName: string = '';
    projectDocumentationList: IPackPD[] = [];
    task: string = '';
    worksCompletionDeadline = ''
    email: string = '';
    invitingTypes: { label: string; value: string }[] = [
        { label: 'Портал', value: 'portal' },
        { label: 'Инженерная часть', value: 'smartcom' },
        { label: 'Портал и Инженерная часть', value: 'smartcomAndPortal' },
    ];
    invitingType = {} as { label: string; value: string };
    accessRole = {} as IMemberRight;
    projectRole = {} as IMemberRole;
    entity = {} as any;
}

class InitialInviteMemberErrors {
    workName: boolean = false;
    projectDocumentationList: boolean = false;
    task: boolean = false;
    worksCompletionDeadline: boolean = false;
    email: boolean = false;
    invitingType: boolean = false;
    accessRole: boolean = false;
    projectRole: boolean = false;
    entity: boolean = false;
}

@Component({
    name: 'InviteMemberForm',

    components: {
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        BaseTextarea: () => import('@components/BaseInputs/BaseTextarea.vue'),
        BaseFileInput: () => import('@components/BaseFileInput/BaseFileInput.vue'),
    },
})
export default class InviteMemberForm extends Vue {
    $refs!: {
        inviteMemberForm: HTMLElement,
    }

    @NSProject.Getter('projectId') projectId!: number;
    @NSProject.Getter('memberRoles') memberProjectRoles!: IProject;
    @NSProjectTeam.State((state: IProjectTeamState) => state.memberRights) memberRights!: IMemberRight[];
    @NSProjectTeam.Action(ProjectTeamActions.A_INVITE_MEMBER) inviteMember!: (payload: { params: any }) => Promise<void>;
    @NSDocuments.Getter('packPDList') packPDList!: IPackPD[];
    @NSAboutProject.Getter('juristicEntities') juristicEntities!: IJuristicEntity[];

    data = new InitialInviteMemberFormData();
    errors: any = new InitialInviteMemberErrors();
    errorText: string = 'Ошибка формы. Проверьте введенные данные';
    errorEmptyText: string = 'Вы не заполнили одно или несколько обязательных полей';
    errorAddressFormat: string = 'Некорректный формат E-mail. Проверьте введенные данные"';

    realtimeValidation: boolean = false;

    mounted() {
       makeFocusInput(this.$refs.inviteMemberForm);
    }

    get dateToday() {
        return formatDate({ date: new Date(), format: 'yyyy-mm-dd'});
    }

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    get entitiesWithoutDuplicateAsOptions(): any[] {
        const tempObj = {};
        return this.juristicEntities
            .filter(({ id }) => (!tempObj[id] && (tempObj[id] = 1)))
            .map((entity) => ({ uuid: entity.id, title: entity.title }));
    }

    async sendForm(): Promise<void> {
        this.validateFields();
        if (!this.errorExists) {
            try {
                await this.inviteMember(
                    { params: this.formInviteMemberPostData() }
                )
                this.$emit('success');
            } catch (e) {
                throw Error(e);
            }
        } else {
            return Promise.reject(this.errorText)
        }
    };

    formInviteMemberPostData(): IInviteMemberPostData {
        return {
            appId: this.projectId,
            email: this.data.email,
            workName: this.data.workName,
            workDescription: this.data.task,
            packPDIds: this.data.projectDocumentationList.map(item => item.uuid.toString()),
            endDate: this.data.worksCompletionDeadline,
            engineerPart: this.data.invitingType.value === 'smartcom' || this.data.invitingType.value === 'smartcomAndPortal',
            portalPart: this.data.invitingType.value === 'portal' || this.data.invitingType.value === 'smartcomAndPortal',
            roleId: this.data.accessRole.uuid,
            projectRoleId: this.data.projectRole.uuid,
            entityId: this.data.entity.uuid,
        }
    }

    resetData() {
        this.realtimeValidation = false;
        this.errors = new InitialInviteMemberErrors();
        this.data = new InitialInviteMemberFormData();
    }

    validateFields() {
        this.errors.workName = !this.data.workName;
        this.errors.projectDocumentationList = !this.data.projectDocumentationList.length;
        this.errors.task = !this.data.task;
        this.errors.worksCompletionDeadline = !this.data.worksCompletionDeadline;
        this.errors.email = !this.data.email;
        this.errors.invitingType = !this.data.invitingType.value;
        this.errors.accessRole = !this.data.accessRole.uuid;
        this.errors.projectRole = !this.data.projectRole.uuid;
        this.errors.entity = !this.data.entity.uuid;

        this.realtimeValidation = true;

        if (!this.errorExists) {
            this.validateEmail();
        } else {
            this.errorText = this.errorEmptyText;
        }
    }

    validateEmail() {
        this.errors.email = !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.data.email);
        this.errorText = this.errorAddressFormat;
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateFields();
        }
    }
}
